<template>
  <main id="content" class="news">
    <section class="visual_intro"
      v-if="fix.id !== ''">
      <div class="visual_wall"
        v-bind:style="{ backgroundImage: 'url(' + fix.topFixImageUrl + ')' }">
      </div>
      <router-link :to="{ name: 'ContentsDetail', params: { ncNo: fix.id} }" class="intro_title">
        <div class="category" v-text="fix.contentCategoryName"></div>
        <div class="title" v-text="fix.title"></div>
      </router-link>
    </section>
    <section class="report_box">
      <div class="news-tab-menu">
        <ul>
          <li class="on"><router-link to="/contents/list">NEWS</router-link></li>
          <li><router-link to="/letter/list">NEWSLETTER</router-link></li>
        </ul>
      </div>
      <div class="box_title">
        <h2 class="title">NEWS</h2>
        <p class="description">{{$t('content.news.list.news_desc')}}</p>
      </div>
      <div class="news_area">
        <div class="news_order">
          <MobileBaseTab
            v-model="tabValue"
            :list="tabList"
            @input="cateCol">
          </MobileBaseTab>
          <div class="order_flag">
            <button role="button" aria-haspopup="listbox" v-text="rangeText"
              :aria-expanded="rangeShow" class="current" @click="rangeCol"></button>
            <ul class="flag_list" role="listbox">
              <li class="flag_item" role="presentation">
                <button class="flag" role="option" @click="rangeCol" :aria-selected="range[0]"
                  v-html="$t('content.news.list.sort.dates')">
                </button>
              </li>
              <li class="flag_item" role="presentation">
                <button class="flag" role="option" @click="rangeCol" :aria-selected="range[1]"
                  v-html="$t('content.news.list.sort.popularity')">
                </button>
              </li>
              <li class="flag_item" role="presentation">
                <button class="flag" role="option" @click="rangeCol" :aria-selected="range[2]"
                  v-html="$t('content.news.list.sort.registrated')">
                </button>
              </li>
            </ul>
          </div>
          <!-- <div class="order_tab" role="tablist">
            <button class="tab" role="tab" @click="cateCol" :aria-selected="cate[0]">ALL</button>
            <button class="tab" role="tab" @click="cateCol" :aria-selected="cate[1]">PEOPLE &
              BUSINESS</button>
            <button class="tab" role="tab" @click="cateCol" :aria-selected="cate[2]">TECH</button>
            <button class="tab" role="tab" @click="cateCol"
              :aria-selected="cate[3]">FINANCE</button>
          </div> -->
        </div>
        <section class="news-list-section">
          <ul class="news_list" v-if="list.length > 0">
            <li class="news_item" v-for="(contnets, index) in list" :key="index">
              <router-link :to="{ name: 'ContentsDetail', params: { ncNo: contnets.id} }"
                class="news">
                <div class="thumb">
                  <template v-if="contnets.youtubeUrl">
                    <img :src="`https://img.youtube.com/vi/${youtubeUrl(contnets.youtubeUrl)}/0.jpg`"
                      alt="" class="img_thumb" data-aos="fade-up">
                  </template>
                  <template v-else-if="contnets.thumbnailFileUrl">
                    <img
                        data-aos="fade-up"
                        :src="contnets.thumbnailFileUrl"
                        alt="" class="img_thumb">
                  </template>
                </div>
                <strong class="category" v-text="contnets.contentCategoryName"></strong>
                <strong class="title" v-text="contnets.title"></strong>
                <div class="update" v-text="datafilter(contnets.insertDate.replace(/\./g, '-'))"></div>
              </router-link>
            </li>
          </ul>
          <ul class="news_list" v-show="dataVisible">
            <div class="no-list-wrap">
              <div class="no-list">
                <h6 data-aos="flip-left" class="aos-init aos-animate"
                  v-html="$t('content.news.list.no_content')">
                </h6>
              </div>
            </div>
          </ul>
        </section>
        <div class="btn-wrap">
          <button button class="btn-more" v-show="moreVisible" @click="contentsList">
            <span class="circle">
              <span class="icon-plus">+</span>
            </span>
            <em class="btn-text">{{$t('content.news.list.more')}}</em>
          </button>
        </div>
        <!-- <div class="news_more" v-show="moreVisible" @click="contentsList">
          <button class="btn_more">
            <span class="ico_more"></span>
            <span class="txt_more">{{$t('content.news.list.more')}}</span>
          </button>
        </div> -->
      </div>
    </section>
  </main>
</template>

<script>

import { CONTENT_CATEGORY } from '@/common/helperUtils';
import ContentsList from '@/views/contents/ContentsList.vue';
import MobileBaseTab from '@/components/base/MobileBaseTab.vue';

export default {
  name: 'MobileContentsList',
  extends: ContentsList,
  components: {
    MobileBaseTab,
  },
  data() {
    return {
      tabValue: 0,
      tabList: ['ALL', 'PEOPLE', 'BUSINESS INSIGHT', 'TECH', 'FINANCE', 'LIFESTYLE'],
    };
  },
  methods: {
    /**
    * 카테고리별 조회
    */
    cateCol(a,b,c) {
      console.log(a,b,c);
      const cate = this.tabValue;
      this.cate.fill(false);
      this.cate[cate] = true;
      this.seach.data.contentCategory = CONTENT_CATEGORY[this.tabList[cate]];
      this.init();
    },
  },
};
</script>
